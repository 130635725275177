import request from '../utils/request';
/**
 *   查询郑州中招订单分摊
 */
export const getZhengZhouApportionOrderData = (param) => {
    return request({
        url: '/sys/orders/apportion/ZhengZhouFinancial',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/***
 * 根据上课记录导出订单分摊 数据
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcelZhengZhouApportion(query) {
    return request({
        url: '/sys/orders/apportion/ZhengZhouFinancialExcel',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}

//查询武汉分摊
export const getwuhanApportionOrderData = (param) => {
    return request({
        url: '/sys/orders/apportion/wuHanFinancial',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//导出武汉分摊
export function exportExcelWuHanApportion(query) {
    return request({
        url: '/sys/orders/apportion/wuHanFinancialExcel',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}