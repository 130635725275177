<template>
  <div class="container">
    <div style="margin-bottom: 20px">
      <el-select size="small" v-model="query.state" clearable placeholder="订单状态" class="handle-select mr10">
        <el-option v-for="(item,index) in orderState" :key="index" :label="item.dictLabel" :value="item.dictLabel"></el-option>
      </el-select>
      <el-input size="small" v-model="query.coachName" clearable placeholder="教练姓名" style="width: 120px" class="mr10"></el-input>
      <el-input size="small" v-model="query.studentName" clearable placeholder="学生姓名" style="width: 120px" class="mr10"></el-input>
      <el-date-picker size="small" style="width: 260px" clearable v-model="startAndEndDate" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button size="small" type="primary" icon="Search" style="margin-left: 10px" @click="query.pageIndex=0,getData()">搜索</el-button>
      <el-popconfirm @confirm="exportExcel" title="确定要导出吗？">
        <template #reference>
          <el-button type="primary" size="small" icon="Download">导出</el-button>
        </template>
      </el-popconfirm>
    </div>
    <el-table v-loading="tableLoading"
              :data="stopOrderList"
              class="table"
              ref="multipleTable"
              :row-style="{height:0+'px'}"
              :cell-style="{padding:0+'px'}"
              height="606px"
              header-cell-class-name="table-header"
    >
      <el-table-column show-overflow-tooltip="true" prop="studentName" label="学生姓名" width="100"></el-table-column>
      <el-table-column label="用户手机号" width="140">
        <template #default="scope">
          <div style="display: flex">
            <div style="width: 150px">
              <span v-if="scope.row.studentPhone!=notEncryptId">{{ filterTel(scope.row.studentPhone) }}</span>
              <span v-else>{{ scope.row.studentPhone }}</span>
            </div>
            <div>
              <span @click="viewPhone(scope.row.studentPhone)">
                <svg v-if="scope.row.studentPhone!=notEncryptId" t="1644395285994" style="width: 20px;height: 20px"
                     class="icon"
                     viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="2629" data-spm-anchor-id="a313x.7781069.0.i16" width="200"
                     height="200">
                  <path
                      d="M660.8 680.4c-10.1 0-20-5.2-25.5-14.4l-59.4-99.1c-8.4-14.1-3.9-32.3 10.2-40.8 14.1-8.4 32.3-3.8 40.8 10.2l59.4 99.1c8.4 14.1 3.9 32.3-10.2 40.8-4.8 2.9-10.1 4.2-15.3 4.2zM819.1 581.3c-7.6 0-15.2-2.9-21-8.7l-79.2-79.3c-11.6-11.6-11.6-30.4 0-42 11.6-11.6 30.4-11.6 42 0l79.2 79.3c11.6 11.6 11.6 30.4 0 42-5.8 5.9-13.4 8.7-21 8.7zM363.2 680.4c-5.2 0-10.5-1.3-15.3-4.2-14.1-8.4-18.6-26.7-10.2-40.8l59.4-99.1c8.4-14.1 26.7-18.7 40.8-10.2 14.1 8.4 18.6 26.7 10.2 40.8L388.7 666c-5.6 9.3-15.4 14.4-25.5 14.4zM204.9 581.3c-7.6 0-15.2-2.9-21-8.7-11.6-11.6-11.6-30.4 0-42l79.2-79.3c11.6-11.6 30.4-11.6 42 0 11.6 11.6 11.6 30.4 0 42l-79.2 79.3c-5.8 5.9-13.4 8.7-21 8.7z"
                      p-id="2630"></path>
                  <path
                      d="M521.6 561.5c-82.8 0-204.7-28.4-314.9-163.5-1.3-1.6-2.3-2.8-3-3.6-10.9-10.8-11.8-28.4-1.7-40.3 10.6-12.5 29.4-14.1 41.9-3.5 1.3 1.1 4.4 4.3 8.9 9.8 95.5 117.1 198.9 141.7 268.8 141.7 120.7 0 258.1-124.6 271.6-144.4 9.1-13.2 27.3-17.5 40.9-8.8 13.5 8.6 18.1 25.8 10 39.6-14.1 23.7-168.8 173-322.5 173z"
                      p-id="2631"></path>
                </svg>
                <svg v-else t="1644397194443" style="width: 20px;height: 20px" class="icon" viewBox="0 0 1024 1024"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="2770" width="200" height="200"><path
                    d="M512 832c-156.448 0-296.021333-98.730667-418.410667-291.605333a52.938667 52.938667 0 0 1 0-56.789334C215.978667 290.730667 355.552 192 512 192c156.448 0 296.021333 98.730667 418.410667 291.605333a52.938667 52.938667 0 0 1 0 56.789334C808.021333 733.269333 668.448 832 512 832z m0-576c-129.514667 0-249.461333 83.850667-360.117333 256C262.538667 684.149333 382.485333 768 512 768c129.514667 0 249.461333-83.850667 360.117333-256C761.461333 339.850667 641.514667 256 512 256z m0 405.333333c-83.210667 0-150.666667-66.858667-150.666667-149.333333S428.789333 362.666667 512 362.666667s150.666667 66.858667 150.666667 149.333333S595.210667 661.333333 512 661.333333z m0-64c47.552 0 86.101333-38.208 86.101333-85.333333S559.552 426.666667 512 426.666667c-47.552 0-86.101333 38.208-86.101333 85.333333s38.549333 85.333333 86.101333 85.333333z"
                    p-id="2771"></path></svg>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" align="center" label="主卡类型" width="110">
        <template #default="scope">
          <el-tag v-if="scope.row.cardName" style="width: 90px;text-align: center" class="sug_info">
            {{ scope.row.cardName != null ? scope.row.cardName : '暂无类型' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100" label="主卡剩余">
        <template #default="scope">{{ scope.row.remaining}} </template>
      </el-table-column>
      <el-table-column width="100" label="主卡使用">
        <template #default="scope">{{ scope.row.ouse}} </template>
      </el-table-column>
      <el-table-column align="center" label="订单状态" width="110">
        <template v-slot="products">
          <el-tag :type="products.row.state == '已激活'? 'success': products.row.state == '已失效'? 'info':'warning'">
            {{ products.row.state }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" align="center" label="副卡类型" width="110">
        <template #default="scope">
          <el-tag v-if="scope.row.sendCard!=null" style="width: 90px;text-align: center" class="sug_info">
            {{ scope.row.sendCard.cardName != null ? scope.row.sendCard.cardName : '暂无类型' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100" label="副卡剩余">
        <template #default="scope">
          <span v-if="scope.row.sendCard!=null">
            {{ scope.row.sendCard.remaining}}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="100" label="副卡使用">
        <template #default="scope">
          <span v-if="scope.row.sendCard!=null">
            {{ scope.row.sendCard.suse}}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="副卡状态" width="110">
        <template v-slot="products">
          <el-tag :type="products.row.state == '已激活'? 'success': products.row.state == '已失效'? 'info':'warning'">
            {{ products.row.state }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100" label="购买数量">
        <template #default="scope">{{ scope.row.buyCourses}} </template>
      </el-table-column>
      <el-table-column width="100" label="购买金额">
        <template #default="scope">{{ scope.row.money}} 元</template>
      </el-table-column>
      <el-table-column width="100" label="课时单价">
        <template #default="scope">
          <span :class="scope.row.price == null ? 'msg':''">
            {{ scope.row.price != null ? scope.row.price+' 元' : '暂无单价' }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" label="本期耗课">
        <template #default="scope">
          <span>
            {{ scope.row.consumptionCourses != null ? scope.row.consumptionCourses+' 节' : '暂无耗课' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="100" label="本期收入">
        <template #default="scope">
          <span :class="scope.row.consumptionMoney == null ? 'msg':''">
             {{ scope.row.consumptionMoney != null ? scope.row.consumptionMoney+' 元' : '暂无收入' }}
          </span>
          </template>
      </el-table-column>
      <el-table-column width="100" label="期末余额">
        <template #default="scope">
          <span>{{ scope.row.remainingMoney != null ? scope.row.remainingMoney+' 元' : '暂无余额' }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" label="期末余课">
        <template #default="scope">
          <span>{{ scope.row.remainingCourses != null ? scope.row.remainingCourses+' 节' : '暂无课时' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="collectionTime" label="各项时间" width="110">
        <template #default="scope">
          <el-popover
            placement="top-start"
            :width="240"
            trigger="hover">
          <div>
            <div>收款日期：{{ scope.row.collectionTime }}</div><el-divider style="margin: 6px 0;" />
            <div>创建日期：{{ scope.row.createTime }}</div><el-divider style="margin: 6px 0;" />
            <div>开始日期：{{ scope.row.startTime != null ? scope.row.startTime : '暂未开卡' }}</div><el-divider style="margin: 6px 0;" />
            <div>结束日期：{{ scope.row.endTime != null ? scope.row.endTime : '暂未开卡' }}</div><el-divider style="margin: 6px 0;" />
          </div>
          <template #reference>
            <el-tag style="width: 90px;text-align: center" class="sug_info">
              查看日期
            </el-tag>
          </template>
        </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="130" label="有效日期（天）">
        <template #default="scope">
          <span :class="scope.row.effectiveDay == null ? 'msg':''">
         {{ scope.row.effectiveDay != null ? scope.row.effectiveDay+' 天' : '暂未开卡' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="160" show-overflow-tooltip="true" label="所属校区">
        <template #default="scope">{{ scope.row.campusName != null ? scope.row.campusName : '' }}</template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="coachName" label="提交人" width="100"></el-table-column>
      <el-table-column width="110" align="center" label="沟通截图">
        <template v-slot="scope">
          <el-image class="code"
                    :src="scope.row.paymentImg != null && scope.row.paymentImg != '' ? JSON.parse(scope.row.paymentImg)[0]:null"
                    :preview-src-list="scope.row.paymentImg != null && scope.row.paymentImg != '' ?JSON.parse(scope.row.paymentImg):null">
            <template #error>
              <div class="code">
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="note" label="备注说明" width="180">
        <template #default="scope">
          <div :class="scope.row.note == '' ? 'msg':''">
            {{ scope.row.note != "" ? scope.row.note : '暂无备注' }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getZhengZhouApportionOrderData,exportExcelZhengZhouApportion} from "@/api/apportion";
import {selectDictByType} from "@/api/dict";
export default {
  name: "ApportionOrder",
  data() {
    return {
      startAndEndDate:null,
      query:{
        pageIndex:0,
        pageSize:10,
        regionId:null,
        state: "",
        campusId: [],
        coachName:"",
        studentName:"",
        startDate:null,
        endDate:null
      },
      update:{
        id:null,
        state:"",
        time:null
      },
      orderState:[],
      campusList:[],
      regionList:[],
      stopOrderList:[],
      tableLoading:false,
      notEncryptId:null,
      pageTotal:0
    }
  },
  methods: {
    placedTop(val){
      console.log(val)

    },
    async getData(){
      if (this.startAndEndDate!=null && this.startAndEndDate.length >= 2) {
        this.query.startDate = this.dateFormat(this.startAndEndDate[0]);
        this.query.endDate = this.dateFormat(this.startAndEndDate[1]);
      }else {
        this.query.startDate = null;
        this.query.endDate = null;
      }
      this.tableLoading= true;
     await  getZhengZhouApportionOrderData(this.query).then(val=>{
        console.log(val);
        this.tableLoading= false;
        if (val.code== 200){
          this.stopOrderList= val.data.records;
          this.pageTotal= val.data.total;
        }
      })
    },
    //导出分摊数据
    exportExcel() {
      // 二次确认删除
      this.tableLoading= true;
      if (this.startAndEndDate!=null && this.startAndEndDate.length >= 2) {
        this.query.startDate = this.dateFormat(this.startAndEndDate[0]);
        this.query.endDate = this.dateFormat(this.startAndEndDate[1]);
      }else {
        this.query.startDate = null;
        this.query.endDate = null;
      }
      exportExcelZhengZhouApportion(this.query).then(res => {
        this.tableLoading= false;
        console.log(res);
        // if (res.code == 200){
        //   const link = document.createElement('a'); //我们用模拟q标签点击事件
        //   var myDate = new Date(); //下载文件的名字
        //   link.href = res.data;
        //   link.setAttribute('download', this.dateFormatC(myDate) + "订单");
        //   document.body.appendChild(link);
        //   /**
        //    * 在这期间，我们可以做自己界面的反应逻辑
        //    **/
        //   link.click(); //点击
        //   document.body.removeChild(link); // 下载完成移除元素
        //   window.URL.revokeObjectURL(URL); // 释放掉blob对象
        // }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    // 选中城市  渲染校区
    selectCity1(val) {
      this.query.campusId = null
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses;
      }
    },
    //手机号隐蔽
    viewPhone(val) {
      if (val == this.notEncryptId) {
        this.notEncryptId = "";
      } else {
        this.notEncryptId = val;
      }
    },
    filterTel(val) {
      //加密手机号
      if (val) {
        return (val.substring(3, 0)) + '****' + (val.substring(7));
      }
    },
    //格式化 年-月-日
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    //格式化 年-月-日 时：分：秒
    dateFormatC(val) {
      var myDate = new Date(val);
      var year = myDate.getFullYear();       //年
      var month = myDate.getMonth() + 1;     //月
      var day = myDate.getDate();            //日
      var hh = myDate.getHours();            //时
      var mm = myDate.getMinutes();
      var ss = myDate.getSeconds();
      const fname = '订单表' + year + '年' + month + '月' + day + '日' + hh + '时' + mm + '分' + ss + '秒';
      return fname;
    }
  },
 async  created() {
   await  this.getData();
    //订单状态
    selectDictByType("orderState").then(res => {
      if (res.code == 200) {
        this.orderState = res.data;
        console.log(this.orderState)
      } else {
        this.$message.error("获取数据失败");
      }
    })
  }
}
</script>

<style scoped>
.handle-select {
  width: 120px;
}
.mr10 {
  margin-right: 10px;
}
.table {
  width: 100%;
  font-size: 14px;
}
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
.msg{
  color: #C0C4CC;
}
.sug_info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.msg{
  color: #C0C4CC;
}
</style>